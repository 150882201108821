import styled from "styled-components";
import { Helmet } from "react-helmet";

import not_found_png from "../../assets/imgs/404.png";

const STYLED_NOT_FOUND = styled.main`
  float: left;
  width: 100%;
  height: auto;
  margin-top: 110px;
  background-color: #e6e6fa;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 576px) {
    margin-top: 95px;
  }

  figure {
    img {
      width: 100%;
    }
  }
`;

export default function NotFoundPage() {
  return (
    <>
      <Helmet>
        <title>404 Error</title>
        <meta name="keywords" content="Error" />
      </Helmet>
      {/* Main */}
      <STYLED_NOT_FOUND>
        <figure>
          <img src={not_found_png} alt="404 Error" />
        </figure>
      </STYLED_NOT_FOUND>
    </>
  );
}
