import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./App.css";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import whatsapp_profile_img from "./assets/imgs/haulmentors-logo.png";

// Pages
// import MainPage from "./pages/Main/MainPage";

// Components
import Header from "./components/Common/Header/Header";
import Footer from "./components/Common/Footer/Footer";
import Spinner from "./components/Common/SpinnerForSuspense/Spinner";
import NotFoundPage from "./pages/NotFound/NotFoundPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicy/PrivacyPolicyPage";

// pages with lazy
const MainPage = lazy(() => import("./pages/Main/MainPage"));
const AboutUsPage = lazy(() => import("./pages/AboutUs/AboutUsPage"));
const CoursesPage = lazy(() => import("./pages/Courses/CoursesPage"));
const DealsPage = lazy(() => import("./pages/BusinessDeals/DealsPage"));

export default function App() {
  return (
    <Suspense fallback={<Spinner />}>
      <WrappedApp />
    </Suspense>
  );
}

function WrappedApp() {
  return (
    <Router>
      {/* SEO */}
      <Helmet>
        <meta
          name="description"
          content="დისპეჩინგის კურსები, დისტანციურად დასაქმება ამერიკაში, Dispatching Courses, Remote Job in USA, Hiring Dispatchers, Car Haulers, Car Carriers, Курсы диспетчеров, Удалённая работа"
        />
      </Helmet>

      {/* WhatsApp */}
      <FloatingWhatsApp
        avatar={whatsapp_profile_img}
        phoneNumber="+995598145911"
        accountName="Giorgi"
        statusMessage="Typically replies within 3 minutes"
        chatMessage={
          "გამარჯობა, რით შეგვიძლია დაგეხმაროთ? 🤝\nHello, how can we help?"
        }
        allowClickAway
        allowEsc
      />

      {/* */}
      <Header />

      <Routes>
        {/* --------------------- routes --------------------- */}

        <Route
          path="/"
          exact
          element={
            <Suspense fallback={<Spinner />}>
              <MainPage />
            </Suspense>
          }
        />

        <Route
          path="/about-us"
          element={
            <Suspense fallback={<Spinner />}>
              <AboutUsPage />
            </Suspense>
          }
        />

        <Route
          path="/dispatcher-courses"
          element={
            <Suspense fallback={<Spinner />}>
              <CoursesPage />
            </Suspense>
          }
        />

        <Route
          path="/business-deals"
          element={
            <Suspense fallback={<Spinner />}>
              <DealsPage />
            </Suspense>
          }
        />

        <Route
          path="/privacy-policy"
          element={
            <Suspense fallback={<Spinner />}>
              <PrivacyPolicyPage />
            </Suspense>
          }
        />

        <Route
          path="*"
          element={
            <Suspense fallback={<Spinner />}>
              <NotFoundPage />
            </Suspense>
          }
        />

        {/* ----                ----------               ----- */}
      </Routes>
      <Footer />
      {/* */}
    </Router>
  );
}
